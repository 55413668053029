import React from "react"

import {Container} from "react-bootstrap"
import {Link} from 'gatsby'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import App from "../components/App"
import Header from "../components/Header"
import Spacer from "../components/Spacer"
import Footer from "../components/Footer"
import SEO from "../components/seo"

export default () => (
  <App>
    <SEO title="Datenschutz"/>
    <Header/>
    <Container id="datenschutz">
      <Spacer variant="large"/>
      <Link className="d-block d-md-none" to="/"><KeyboardBackspaceIcon/> Start</Link>
      <h1 className="d-md-block d-none">Datenschutzerklärung</h1>
      <h3 className="d-block d-md-none">Datenschutzerklärung</h3>
            <br/><br/>
            <h3>§ 1 Information über die Erhebung personenbezogener Daten</h3>
            <br/>
            (1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
            <br/><br/>
            (2) Verantwortlich gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) ist Zorrilla Media GbR, vertreten durch Thomas Bruckner und Ricardo Ruiz-Zorrilla Morales, Im Klinker 7, 65474 Bischofsheim, hello@zorrillamedia.de, +49 6144 920499910 (siehe Impressum).
            <br/><br/>
            (3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
            <br/><br/>
            (4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
            <br/><br/><br/>
            <h3>§ 2 Ihre Rechte</h3>
            <br/>
            (1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
            <br/>- Recht auf Auskunft,
            <br/>- Recht auf Berichtigung oder Löschung,
            <br/>- Recht auf Einschränkung der Verarbeitung,
            <br/>- Recht auf Widerspruch gegen die Verarbeitung,
            <br/>- Recht auf Datenübertragbarkeit.
            <br/><br/>
            (2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.
            <br/><br/><br/>
            <h3>§ 3 Erhebung personenbezogener Daten bei Besuch unserer Website</h3>
            <br/>
            (1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):
            <br/>- IP-Adresse
            <br/>- Datum und Uhrzeit der Anfrage
            <br/>- Zeitzonendifferenz zur Greenwich Mean Time (GMT)
            <br/>- Inhalt der Anforderung (konkrete Seite)
            <br/>- Zugriffsstatus/HTTP-Statuscode
            <br/>- jeweils übertragene Datenmenge
            <br/>- Website, von der die Anforderung kommt
            <br/>- Browser
            <br/>- Betriebssystem und dessen Oberfläche
            <br/>- Sprache und Version der Browsersoftware.
            <br/><br/>
            (2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.
            <br/><br/>
            (3) Einsatz von Cookies:
            <br/><br/>(a) Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:
            <br/>- Transiente Cookies (dazu b)
            <br/>- Persistente Cookies (dazu c).
            <br/><br/>(b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
            <br/><br/>(c) Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
            <br/><br/>(d) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.
            <br/><br/><br/>
            <h3>§ 4 Weitere Funktionen und Angebote unserer Website</h3>
            <br/>
            (1) Neben der rein informatorischen Nutzung unserer Website bieten wir verschiedene Leistungen an, die Sie bei Interesse nutzen können. Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten Grundsätze zur Datenverarbeitung gelten.
            <br/><br/>
            (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister. Diese wurden von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig kontrolliert.
            <br/><br/>
            (3) Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat außerhalb des Europäischen Wirtschaftsraumen (EWR) haben, informieren wir Sie über die Folgen dieses Umstands in der Beschreibung des Angebotes.
            <br/><br/><br/>
            <h3>§ 5 Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</h3>
            <br/>
            (1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.
            <br/><br/>
            (2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.
            <br/><br/>
            (3) Selbstverständlich können Sie der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten informieren: Zorrilla Media GbR, vertreten durch Thomas Bruckner und Ricardo Ruiz-Zorrilla Morales, Im Klinker 7, 65474 Bischofsheim, hello@zorrillamedia.de, +49 6144 920499910 (siehe Impressum).
            <br/><br/><br/>
            <h3></h3>
            <h3>§ 6 Einsatz von Matomo Webanalyse</h3>
            <br/>
            (1) Diese Website benutzt Matomo, eine Open-Source-Software zur Webanalyse. Der Dienst ermöglicht die statistischen Auswertung der Besucherzugriffe zu Verbesserungszwecken.
            <br/><br/>
            (2) Es werden nach Ihrer Zustimmung sog. „Cookies“ verwendet, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
            <br/><br/>
            (3) Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
            <br/><br/>
            (4)Ein Rückschluss auf eine bestimmte Person ist dabei nicht möglich, da Ihre IP-Adresse sofort nach der Verarbeitung und vor der Speicherung anonymisiert wird. Ebenfalls können Sie über die "Do Not Track" Einstellung Ihres Browsers eine Speicherung Ihrer Daten verhindern.
            <br/><br/>
            (5) Wir nutzen diesen Analysedienst, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten.
            <br/><br/>
            (6) Die Server zur Sammlung und Auswertung der Analysen wird von uns selbst auf der Infrastruktur dieser Webseite betrieben. Es werden keine Daten in Bezug auf die Webanalyse an Drittanbieter oder fremde Rechenzentren weitergegeben.
            <br/><br/>
            (7) Informationen des Anbieters: Information zu Matomo und der DSGVO: <a href="https://matomo.org/gdpr/">https://matomo.org/gdpr/</a>, Übersicht zum Datenschutz: <a href="https://matomo.org/100-data-ownership/">https://matomo.org/100-data-ownership/</a>, sowie: <a href="https://matomo.org/privacy/">https://matomo.org/privacy/</a>.
            <br/><br/><br/>
            <h3>§ 7 Einsatz von Mapbox</h3>
            <br/>
            (1) Diese Website benutzt zur Darstellung von Orten den Kartendienst Mapbox des Anbieters MapBox Inc. mit Sitz in 740 15th St NW, Washington, DC 20005, USA
            <br/><br/>
            (2) Bei der Nutzung von Mapbox wird eine Anfrage zum Laden der Karten an die Server von Mapbox geschickt. Diese Anfrage enthält die IP-Adresse Ihres Browsers. Wir haben keinen Einfluss auf diese Datenübertragung.
            <br/><br/>
            (3) Die Verwendung von Mapbox ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 dar, indem eine ansprechende Darstellung unseres Internetauftritts ermöglichst wird.
            Der Anbieter dieses Dienstes stellt Informationen Datenschutz der Nutzerdaten unter folgender Adresse bereit: <a href="https://www.mapbox.com/privacy">https://www.mapbox.com/privacy/</a>.
            <br/><br/><br/>
            <h3>§ 8 Einsatz von FreshDesk</h3>
            <br/>
            (1) Wir verwenden FreshDesk, ein Angebot des Anbieters Freshworks Inc., um Anfragen per E-Mail an info@zorrillamedia.de und über unser Kontaktformular zu beantworten sowie um E-Mails versenden zu können.
            <br/><br/>
            (2) Rechtsgrundlagen hierfür gemäß DSGVO, sofern und soweit anwendbar sind: Art. 6 Abs. 1 Bst. b u. f DSGVO.
            <br/><br/>
            (3) Freshworks Inc. ist sowohl nach dem EU-amerikanischen <a href="https://www.privacyshield.gov/">Privacy Shield</a> zertifiziert und gewährleistet dadurch einen angemessenen Datenschutz.
            Der Eintrag in die Privacy Shield Liste ist unter <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnbQAAS">https://www.privacyshield.gov/participant?id=a2zt0000000GnbQAAS</a> aufrufbar.
            <br/><br/>
            (4) Weitere Informationen zu Art, Umfang und Zweck der Datenbearbeitung sind von Freshworks Inc. an folgenden Stellen veröffentlicht:
            <br/> 
            <a href="https://www.freshworks.com/privacy/">Datenschutzerklärung von Freshworks</a>
            <br/>
            <a href="https://www.freshworks.com/privacy/gdpr/">Datenschutz und Bekenntnis zur neuen Datenschutz-Grundverordnung der EU (EU-DSGVO)</a>
            <br/><br/><br/>
            <h3>§ 9 Einsatz von hCaptcha</h3>
            <br/>
            (1) Zur Vermeidung von automatisierten Anfragen und Spam verwenden wir eine Captcha Lösung in unserem Kontaktformular. Es wird hierfür der Dienst hCaptcha genutzt.
            Anbieter ist Intuition Machines Inc., 350 Alabama St, San Francisco, CA 94110, USA.
            <br/><br/>
            (2) Der Benutzer muss zur Nutzung des Kontaktformulars gegebenenfalls eine kleine Aufgabe lösen, um zu bestätigen, dass es sich um eine tatsächliche menschliche Interaktion handelt.
            Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Zur Feststellung ob es sich um einen echtes menschliches Verhalten handelt evaluiert hCaptcha verschiedene Informationen (z.B. die IP-Adresse, Besuchsdauer oder Mausbewegungen)
            <br/><br/>
            (3) Weitere Informationen zu Art, Umfang und Zweck der Datenbearbeitung sind vom Anbieter an folgenden Stellen veröffentlicht:<br/> 
            <a href="https://www.hcaptcha.com/privacy.">Datenschutzerklärung von hCaptcha</a>
            <br/><br/><br/><br/>

      <Spacer variant="medium"/>
    </Container>
    <section className="bg-dark text-light pt-4">
      <Footer />
    </section>
  </App>
)
